import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  constructor(private router: Router) { 
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Push page view event to GTM data layer
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        event: 'page_view',
        page_path: event.urlAfterRedirects
      });
      console.log('GTM Data Pushed');
    });   
  }  
}
